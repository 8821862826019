function currency($type) {
    "use strict";
    function hideCurrency(className) {
        Array.from(document.getElementsByClassName(className))
            .forEach(
                element => element.classList.add("price__currency--hidden")
            );
    }
    hideCurrency("price__pounds");
    hideCurrency("price__usd");
    hideCurrency("price__euro");

    Array.from(document.getElementsByClassName("price__" + $type))
        .forEach(
            element => element.classList.remove("price__currency--hidden")
        );
}

if (document.documentElement.lang == "de") {
    document.getElementById("change-currency__euro").checked = true;
}

const currencyLink = window.location.search.match(/currency=(pounds|euro|usd)/);
if (currencyLink !== null &&
    currencyLink.length <= 2 &&
    document.getElementById("change-currency__" + currencyLink[1]) !== null) {

    document.getElementById("change-currency__" + currencyLink[1]).checked = true;
}

scriptSync("utils", () => {
    addEventAll("input[name='change-currency']",
        (e) => currency(e.currentTarget.dataset.currency),
        "change");
});
